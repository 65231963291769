import {
  formatDollarAmount,
  useReportFiltersForm,
  DataGridPage,
  formatDateTime,
  getBitcoinFromSats,
  IRevenue,
  useAccount,
  dayjs,
  useRevenue,
  BaseDataGrid,
} from "@synota-io/synota-shared-ui";
import { useEffect } from "react";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { ReportFiltersToolbarLinked } from "../../../shared/reports/ReportFiltersToolbarLinked";
import { useNavigate } from "react-router-dom";

const columns: GridColDef<IRevenue>[] = [
  {
    field: "revenueType",
    headerName: "Source",
    flex: 1,
    width: 140,
    minWidth: 90,
  },
  {
    field: "time",
    type: "number",
    headerName: "Date and Time (UTC)",
    valueFormatter: (value) => formatDateTime(value),
    flex: 1,
    width: 220,
    minWidth: 120,
  },
  {
    field: "revenueAmount",
    type: "number",
    headerName: "Amount (BTC)",
    valueFormatter: (value) => getBitcoinFromSats(value),
    flex: 1,
    width: 140,
    minWidth: 90,
  },
  {
    field: "usdAmount",
    type: "number",
    headerName: "Amount (USD)",
    valueFormatter: (value) => (value >= 0 ? formatDollarAmount(value) : null),
    flex: 1,
    width: 140,
    minWidth: 90,
  },
  {
    field: "feeAmount",
    type: "number",
    headerName: "Fee (BTC)",
    valueFormatter: (value) => getBitcoinFromSats(value),
    flex: 1,
    width: 140,
    minWidth: 90,
  },
  {
    field: "txidOrPaymentHash",
    type: "number",
    headerName: "Transaction / Payment Hash",
    valueGetter: (_value, row) => (row.txid ? row.txid : row.paymentHash),
    flex: 1,
    width: 140,
    minWidth: 90,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    width: 140,
    minWidth: 90,
  },
];

export const ConsumerDepositsPage = () => {
  const { isConsumer } = useAccount();

  const navigate = useNavigate();
  const apiRef = useGridApiRef();

  const defaultDates = {
    startDate: dayjs().startOf("month"),
    endDate: dayjs().endOf("day"),
  };

  useEffect(() => {
    if (isConsumer === false) {
      navigate("/");
    }
  }, [isConsumer, navigate]);

  const {
    form: { control },
    values,
    input,
  } = useReportFiltersForm({
    defaultDates,
    report: {},
  });

  const { revenueItems, isLoading } = useRevenue(input);

  return (
    <DataGridPage
      title="Deposit History"
      recalculateHeightDeps={values}
      toolbar={<ReportFiltersToolbarLinked control={control} />}
    >
      <BaseDataGrid apiRef={apiRef} loading={isLoading} columns={columns} rows={revenueItems} />
    </DataGridPage>
  );
};
