import { Box, Stack, ButtonProps } from "@mui/material";
import {
  Announcement,
  ButtonRow,
  ConfirmationModal,
  FormButton,
  IAdhocInvoice,
  Modal,
  UseModalProps,
  useAccount,
  useAdhocInvoicesMutations,
  useConfirmationModal,
} from "@synota-io/synota-shared-ui";
import { AdhocDetailsList } from "./AdhocDetailsList";
import { RejectAdhocModal, RejectAdhocModalState } from "./RejectAdhocModal";

type Props = UseModalProps & {
  charge: IAdhocInvoice | null;
  onMutationSuccess: () => void;
};

const CloseButton = ({ onClose, ...props }: { onClose(): void } & ButtonProps) => (
  <FormButton
    variant="text"
    color="error"
    {...props}
    onClick={() => {
      onClose();
    }}
  >
    Close
  </FormButton>
);

export const AdhocDetailsModal = ({ open, onClose, charge, onMutationSuccess }: Props) => {
  const { isConsumer, isSupplier, jwt, isAdmin } = useAccount();
  const { approveCharge, rejectCharge, voidCharge, isPending } = useAdhocInvoicesMutations();

  const approveModal = useConfirmationModal();
  const voidModal = useConfirmationModal();
  const rejectModal = useConfirmationModal<RejectAdhocModalState>();

  if (!charge) {
    return null;
  }

  const actions =
    isAdmin && !charge.consumer_verified && !charge.void && !charge.rejected ? (
      <Stack px={4} pb={2} flexGrow={1}>
        <ButtonRow>
          {isConsumer ? (
            <>
              <FormButton
                variant="text"
                color="error"
                isLoading={isPending}
                onClick={() => {
                  rejectModal.onOpen({
                    onConfirm({ reason }) {
                      rejectCharge(
                        {
                          jwt,
                          additional_charge_shared_uuid: charge.shared_uuid,
                          reject_reason: reason,
                        },
                        {
                          onSuccess: () => {
                            onMutationSuccess();
                            rejectModal.onClose();
                          },
                        },
                      );
                    },
                  });
                }}
              >
                Reject Invoice
              </FormButton>
              <Box flexGrow={1}>
                <FormButton
                  isLoading={isPending}
                  color="secondary"
                  onClick={() => {
                    approveModal.onOpen({
                      acceptLabel: "Approve Invoice",
                      onConfirm() {
                        approveModal.onClose();
                        approveCharge({ id: charge.id, jwt }, { onSuccess: onMutationSuccess });
                      },
                    });
                  }}
                  fullWidth
                >
                  Approve Invoice
                </FormButton>
              </Box>
            </>
          ) : null}
          {isSupplier ? (
            <>
              <CloseButton onClose={onClose} />
              <Box flexGrow={1}>
                <FormButton
                  color="error"
                  isLoading={isPending}
                  onClick={() => {
                    voidModal.onOpen({
                      acceptLabel: "Void Invoice",
                      onConfirm() {
                        voidModal.onClose();
                        voidCharge(
                          { jwt, additional_charge_shared_uuid: charge.shared_uuid },
                          { onSuccess: onMutationSuccess },
                        );
                      },
                    });
                  }}
                  fullWidth
                >
                  Void Invoice
                </FormButton>
              </Box>
            </>
          ) : null}
        </ButtonRow>
        {isConsumer ? (
          <Stack pt={7} pb={3} alignItems="center" justifyContent="center">
            <Announcement maxWidth="90%">
              Approved invoice payments will be attempted frequently and when funds are available.
            </Announcement>
          </Stack>
        ) : null}
      </Stack>
    ) : (
      <CloseButton fullWidth onClose={onClose} />
    );

  return (
    <Modal
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={onClose}
      title="Ad Hoc Invoice"
      actions={actions}
    >
      <AdhocDetailsList charge={charge} />
      {isSupplier && voidModal.open ? <ConfirmationModal {...voidModal} /> : null}
      {isConsumer && approveModal.open ? <ConfirmationModal {...approveModal} /> : null}
      {isConsumer && rejectModal.open ? <RejectAdhocModal {...rejectModal} /> : null}
    </Modal>
  );
};
