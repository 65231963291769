import { OverridableComponent, OverrideProps } from "@mui/material/OverridableComponent";
import {
  FlexLoadingBlock,
  Page,
  useAccount,
  useContractsSummary,
} from "@synota-io/synota-shared-ui";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren;

interface TypeMap<
  AdditionalProps = unknown,
  RootComponent extends React.ElementType = typeof Page,
> {
  props: AdditionalProps & Props;
  defaultComponent: RootComponent;
}

export type LoadingPageProps<
  RootComponent extends React.ElementType = TypeMap["defaultComponent"],
  AdditionalProps = unknown,
> = OverrideProps<TypeMap<AdditionalProps, RootComponent>, RootComponent>;

export const LoadingPage: OverridableComponent<TypeMap<unknown, typeof Page>> = <
  RootComponent extends React.ElementType = typeof Page,
>(
  props: LoadingPageProps<RootComponent>,
) => {
  const { children, component: Component = Page as RootComponent, ...otherProps } = props;

  const { isLoading } = useAccount();
  const { isLoading: isLoadingContracts } = useContractsSummary();

  if (!children || isLoading || isLoadingContracts) {
    return <FlexLoadingBlock variant="logo" pb={18} />;
  }

  return <Component {...otherProps}>{children}</Component>;
};
