import {
  ActionButton,
  IAdhocInvoice,
  REPORT_ENDPOINTS,
  formatDate,
  formatDollarAmount,
  useAccount,
  useAdhocInvoices,
  useBreakpoint,
  useCurrencyConverter,
  useModal,
  useReportCsv,
  useReportFiltersForm,
  DataGridPage,
  useGridServerPagination,
  CreateAdhocInvoiceInput,
  BaseDataGrid,
  useDefaultError,
} from "@synota-io/synota-shared-ui";
import { useEffect, useState } from "react";
import { AdhocDetailsModal } from "../components/AdhocDetailsModal";
import { generatePath, useLocation, useNavigate, useParams } from "react-router-dom";
import { Add, Download } from "@mui/icons-material";
import { CreateAdhocFormModal } from "../components/CreateAdhocFormModal";
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridPaginationModel,
  useGridApiRef,
} from "@mui/x-data-grid";
import { ContractCell } from "../cells/ContractCell";
import { InvoiceStatusCell } from "../cells/InvoiceStatusCell";
import { PaymentStatusCell } from "../cells/PaymentStatusCell";
import { ActionsCell } from "../cells/ActionsCell";
import { ReportFiltersToolbarLinked } from "../../../shared/reports/ReportFiltersToolbarLinked";
import { ADHOC_INVOICE_PATH } from "../../../paths";

const defaultDates = {
  startDate: null,
  endDate: null,
};

const adhocColDef: GridColDef<IAdhocInvoice>[] = [
  {
    field: "contract_name",
    headerName: "Contract",
    renderCell: ({ row }) => <ContractCell row={row} />,
    flex: 1,
    width: 220,
    minWidth: 120,
  },
  {
    field: "header_title",
    headerName: "Title",
    flex: 1,
    width: 170,
    minWidth: 70,
  },
  {
    field: "amount_in_currency",
    type: "number",
    headerName: "Amount",
    valueFormatter: (value) => (value >= 0 ? formatDollarAmount(value) : null),
    width: 140,
    minWidth: 90,
    flex: 1,
  },
  {
    field: "begin_time",
    type: "number",
    headerName: "Start Date",
    description: "Service start date.",
    valueFormatter: (value) => formatDate(value),
    width: 140,
    minWidth: 90,
  },
  {
    field: "end_time",
    headerName: "End Date",
    description: "Service end date.",
    valueFormatter: (value) => formatDate(value),
    width: 140,
    minWidth: 90,
  },
  {
    field: "consumer_third_party_usd_payment_id",
    type: "number",
    headerName: "ACH ID",
    width: 110,
    minWidth: 70,
  },
  {
    field: "last_payment_id",
    type: "number",
    headerName: "Payment ID",
    width: 110,
    minWidth: 70,
  },
  {
    field: "status",
    headerName: "Invoice Status",
    renderCell: ({ row }) => <InvoiceStatusCell row={row} />,
    width: 155,
    minWidth: 90,
    flex: 1,
  },
  {
    field: "detailed_status",
    headerName: "Payment Status",
    renderCell: ({ row }) => <PaymentStatusCell row={row} />,
    width: 155,
    minWidth: 90,
    flex: 1,
  },
];

export const AdhocPage = () => {
  const isTablet = useBreakpoint("sm");
  const params = useParams();
  const apiRef = useGridApiRef();

  const navigate = useNavigate();

  const { search } = useLocation();
  const { isAdmin, isSupplier, paymentMethod, refetch: refetchSelf } = useAccount();

  const { isBluePenguin: isCurrencyConverterBluePenguin } = useCurrencyConverter();
  const isBluePenguin = Boolean(isCurrencyConverterBluePenguin || paymentMethod?.isBluePenguin);

  const actionsModal = useModal();
  const createModal = useModal();

  const onDetailsOpen = (row: IAdhocInvoice) => {
    navigate(
      {
        pathname: generatePath(ADHOC_INVOICE_PATH, { chargeId: row.shared_uuid }),
        search,
      },
      { replace: true },
    );
  };

  const columns: GridColDef<IAdhocInvoice>[] = [
    ...adhocColDef,
    {
      field: "actions",
      headerName: "Actions",
      renderCell: ({ row }) => <ActionsCell row={row} onDetailsClick={() => onDetailsOpen(row)} />,
      type: "actions",
      disableExport: true,
      disableReorder: true,
    },
  ];

  const [paginationModel, onPaginationModelChange] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 20,
  });

  const {
    form: { control },
    input,
    getFilename,
    values,
  } = useReportFiltersForm({
    report: REPORT_ENDPOINTS.AD_HOC_INVOICES,
    defaultDates,
  });

  const {
    download,
    error: csvError,
    isFetching: isFetchingCsv,
  } = useReportCsv({ input, filename: getFilename() });

  const { charges, hasNextPage, refetch, isFetching, error } = useAdhocInvoices({
    ...input,
    pagination_i: paginationModel.page,
  });

  useDefaultError(error, "There was an error while loading ad hoc invoices, please try again.");

  useEffect(() => {
    if (!actionsModal.open && !isFetching && params.chargeId) {
      actionsModal.onOpen({ chargeId: params.chargeId });
    }
  }, [actionsModal, isFetching, params.chargeId]);

  const [columnVisibility, setColumnVisibility] = useState<GridColumnVisibilityModel>({});
  useEffect(() => {
    setColumnVisibility({
      last_payment_id: isTablet,
      header_title: isTablet,
      consumer_third_party_usd_payment_id: isBluePenguin,
    });
  }, [isTablet, charges, isBluePenguin]);

  const [lastCreation, setLastCreation] = useState<null | CreateAdhocInvoiceInput>(null);
  useEffect(() => {
    if (charges[0] && lastCreation?.begin_time === charges[0].begin_time) {
      apiRef.current?.setRowSelectionModel([charges[0].shared_uuid]);
    }
  }, [apiRef, charges, lastCreation]);
  const onChargeCreated = (values: CreateAdhocInvoiceInput) => {
    setLastCreation(values);
    refetch();
  };

  const paginationProps = useGridServerPagination({
    hasNextPage,
    currentPageRowCount: charges.length,
    paginationModel,
    onPaginationModelChange,
  });

  return (
    <DataGridPage
      title="Ad Hoc Invoices"
      recalculateHeightDeps={values}
      toolbar={<ReportFiltersToolbarLinked control={control} />}
      actions={
        <>
          <ActionButton
            isLoading={isFetchingCsv}
            disabled={Boolean(csvError || !charges?.length)}
            title="Download as CSV"
            color="primary"
            size="small"
            onClick={() => download()}
          >
            <Download />
          </ActionButton>
          {isSupplier && isAdmin ? (
            <ActionButton
              title="New Invoice"
              color="primary"
              size="small"
              onClick={() => createModal.onOpen()}
            >
              <Add />
            </ActionButton>
          ) : null}
        </>
      }
    >
      <>
        <BaseDataGrid
          apiRef={apiRef}
          loading={isFetching}
          columns={columns}
          rows={charges}
          onRowDoubleClick={({ row }) => onDetailsOpen(row)}
          getRowId={(row) => row.shared_uuid}
          columnVisibilityModel={columnVisibility}
          onColumnVisibilityModelChange={setColumnVisibility}
          {...paginationProps}
        />
        <AdhocDetailsModal
          key={params.chargeId}
          onMutationSuccess={() => {
            refetch();
            refetchSelf();
          }}
          charge={charges?.find((c) => c.shared_uuid === params.chargeId) || null}
          {...actionsModal}
          onClose={() =>
            navigate(
              { pathname: "..", search },
              { preventScrollReset: true, relative: "path", replace: true },
            )
          }
        />
        {isSupplier && createModal.open ? (
          <CreateAdhocFormModal onSuccess={onChargeCreated} {...createModal} />
        ) : null}
      </>
    </DataGridPage>
  );
};
