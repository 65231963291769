import {
  DataGridPage,
  BaseDataGrid,
  IUserGroupUser,
  useUsers,
  useBreakpoint,
} from "@synota-io/synota-shared-ui";
import { GridColDef, GridColumnVisibilityModel, useGridApiRef } from "@mui/x-data-grid";
import { UserEmailCell } from "../tables/userGroup/UserEmailCell";
import { UserRoleCell } from "../tables/userGroup/UserRoleCell";
import { UserActionsCell } from "../tables/userGroup/UserActionsCell";
import { useEffect, useState } from "react";
import { UserStatusCell } from "../tables/userGroup/UserStatusCell";

const usersColDef: GridColDef<IUserGroupUser>[] = [
  {
    field: "role",
    headerName: "Role",
    renderCell: ({ row }) => <UserRoleCell row={row} />,
    width: 120,
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: ({ row }) => <UserStatusCell row={row} />,
    width: 120,
  },
  {
    field: "email",
    headerName: "Email",
    renderCell: ({ row }) => <UserEmailCell row={row} />,
    flex: 1,
    width: 220,
    minWidth: 120,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
  },
];

export const UserGroupPage = () => {
  const apiRef = useGridApiRef();
  const isTablet = useBreakpoint("md");

  const { users, isFetching, refetch } = useUsers();

  const [columnVisibility, setColumnVisibility] = useState<GridColumnVisibilityModel>({});
  useEffect(() => {
    setColumnVisibility({
      description: isTablet,
    });
  }, [isTablet]);

  const columns: GridColDef<IUserGroupUser>[] = [
    ...usersColDef,
    {
      field: "actions",
      headerName: "Actions",
      renderCell: ({ row }) => {
        return <UserActionsCell row={row} onRefetch={refetch} />;
      },
      width: 180,
      minWidth: 120,
    },
  ];

  return (
    <DataGridPage title="Users">
      <BaseDataGrid
        apiRef={apiRef}
        getRowId={(row) => row.email}
        columnVisibilityModel={columnVisibility}
        onColumnVisibilityModelChange={setColumnVisibility}
        loading={isFetching}
        columns={columns}
        rows={users}
      />
    </DataGridPage>
  );
};
