import * as React from "react";

import { Routes, Route, Navigate } from "react-router-dom";
import { ContractPage } from "./modules/contracts/pages/ContractPage";
import { DashboardPage } from "./modules/dashboard/pages/DashboardPage";
import { SettingsPage } from "./modules/settings/pages/SettingsPage";
import { ContractsPage } from "./modules/contracts/pages/ContractsPage";
import { DashboardOutlet } from "./modules/dashboard/DashboardOutlet";
import {
  ACCOUNT_PATH,
  CONTRACT_PATH,
  DASHBOARD_PATH,
  EXPLORER_SETTLEMENTS_PATH,
  EXPLORER_REPORTS_PATH,
  LOGIN_PATH,
  EXPLORER_DEPOSIT_HISTORY_PATH,
  SIGNUP_PATH,
  SETTINGS_PATH,
  EXPLORER_PATH,
  CONTRACTS_PATH,
  RESET_PASSWORD_PATH,
  ACCOUNT_SET_UP_PATH,
  SETTINGS_ALERTS_PATH,
  ADHOC_INVOICES_PATH,
  CHARGES_PATH,
  CONTRACT_EXPOSURE_PATH,
  EXPLORER_CHANGELOG_PATH,
  ADHOC_INVOICE_PATH,
  EXPLORER_USERS_PATH,
  ACCOUNT_TOS_PATH,
  CREDITS_PATH,
  CREDIT_PATH,
} from "./paths";
import { LayoutContainer } from "./shared/layout/LayoutContainer";
import { AccountOutlet } from "./modules/account/AccountOutlet";
import { LoginPage } from "./modules/account/pages/LoginPage";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ProtectedPage } from "./shared/pages/ProtectedPage";
import { ExplorerOutlet } from "./modules/explorer/ExplorerOutlet";
import { SettlementsPage } from "./modules/explorer/pages/SettlementsPage";
import { ReportsPage } from "./modules/explorer/pages/ReportsPage";
import { ConsumerDepositsPage } from "./modules/explorer/pages/ConsumerDepositsPage";
import { ContractsOutlet } from "./modules/contracts/ContractsOutlet";
import { LayoutProvider, NotFound } from "@synota-io/synota-shared-ui";
import {
  FEATURE_ENABLE_AR_EXPOSURE,
  FEATURE_ENABLE_DONATION_SHOWCASE_MODE,
  FEATURE_ENABLE_MAINTENANCE_MODE,
} from "./utils/environment";
import { ThrowTestingError } from "./ThrowTestingError";
import { AlertsPage } from "./modules/settings/pages/AlertsPage";
import { MaintenancePage } from "./shared/pages/MaintenancePage";
import { SettingsOutlet } from "./modules/settings/SettingsOutlet";
import { ChargesOutlet } from "./modules/charges/ChargesOutlet";
import { AdhocPage } from "./modules/charges/pages/AdhocPage";
import { ContractExposurePage } from "./modules/contracts/pages/ContractExposurePage";
import { ChangeLogPage } from "./modules/explorer/pages/ChangeLogPage";
import { UserGroupPage } from "./modules/explorer/pages/UserGroupPage";
import { SuspensePage } from "./shared/pages/SuspensePage";
import { ErrorPage } from "./shared/pages/ErrorPage";
import { CreditsPage } from "./modules/charges/pages/CreditsPage";

const TermsOfServicePage = React.lazy(() => import("./modules/account/pages/TermsOfServicePage"));
const ResetPasswordPage = React.lazy(() => import("./modules/account/pages/ResetPasswordPage"));
const SignupPage = React.lazy(() => import("./modules/account/pages/SignupPage"));
const DonationsDashboard = React.lazy(() => import("./modules/donations/pages/DonationsDashboard"));

function AppRoutes() {
  if (FEATURE_ENABLE_MAINTENANCE_MODE) {
    return (
      <Routes>
        <Route path="*" element={<MaintenancePage />} />
      </Routes>
    );
  }

  if (FEATURE_ENABLE_DONATION_SHOWCASE_MODE) {
    return (
      <Routes>
        <Route
          path="*"
          element={
            <SuspensePage>
              <DonationsDashboard />
            </SuspensePage>
          }
        />
      </Routes>
    );
  }

  return (
    <>
      <Routes>
        <Route path={ACCOUNT_PATH} element={<AccountOutlet />}>
          <Route index element={<Navigate to={LOGIN_PATH} />} />
          <Route path={LOGIN_PATH} element={<LoginPage />} />
          <Route
            path={SIGNUP_PATH}
            element={
              <SuspensePage>
                <SignupPage />
              </SuspensePage>
            }
          />
          <Route
            path={RESET_PASSWORD_PATH}
            element={
              <SuspensePage>
                <ResetPasswordPage />
              </SuspensePage>
            }
          />
          <Route
            path={ACCOUNT_SET_UP_PATH}
            element={
              <SuspensePage>
                <ResetPasswordPage setUp />
              </SuspensePage>
            }
          />
          <Route
            path={ACCOUNT_TOS_PATH}
            element={
              <SuspensePage>
                <TermsOfServicePage />
              </SuspensePage>
            }
          />
        </Route>

        <Route
          path={DASHBOARD_PATH}
          element={
            <ProtectedPage>
              <LayoutProvider>
                <LayoutContainer />
              </LayoutProvider>
            </ProtectedPage>
          }
        >
          <Route element={<DashboardOutlet />}>
            <Route index element={<DashboardPage />} />
          </Route>

          <Route path={CHARGES_PATH} element={<ChargesOutlet />}>
            <Route index element={<Navigate to={ADHOC_INVOICES_PATH} />} />
            <Route path={ADHOC_INVOICES_PATH} element={<AdhocPage />} />
            <Route path={ADHOC_INVOICE_PATH} element={<AdhocPage />} />
            <Route path={CREDITS_PATH} element={<CreditsPage />} />
            <Route path={CREDIT_PATH} element={<CreditsPage />} />
          </Route>

          <Route path={SETTINGS_PATH} element={<SettingsOutlet />}>
            <Route index element={<SettingsPage />} />
            <Route path={SETTINGS_ALERTS_PATH} element={<AlertsPage />} />
          </Route>

          <Route path={CONTRACTS_PATH} element={<ContractsOutlet />}>
            <Route
              handle={{ breadcrumb: { title: "Contracts", path: CONTRACTS_PATH } }}
              index
              element={<ContractsPage />}
            />

            <Route path={CONTRACT_PATH} element={<ContractPage />} />

            {FEATURE_ENABLE_AR_EXPOSURE && (
              <Route path={CONTRACT_EXPOSURE_PATH} element={<ContractExposurePage />} />
            )}
          </Route>

          <Route path={EXPLORER_PATH} element={<ExplorerOutlet />}>
            <Route index element={<Navigate to={EXPLORER_SETTLEMENTS_PATH} />} />
            <Route path={EXPLORER_SETTLEMENTS_PATH} element={<SettlementsPage />} />
            <Route path={EXPLORER_REPORTS_PATH} element={<ReportsPage />} />
            <Route path={EXPLORER_DEPOSIT_HISTORY_PATH} element={<ConsumerDepositsPage />} />
            <Route path={EXPLORER_CHANGELOG_PATH} element={<ChangeLogPage />} />
            <Route path={EXPLORER_USERS_PATH} element={<UserGroupPage />} />
          </Route>
        </Route>

        <Route path="throw-testing-error" element={<ThrowTestingError />} />
        <Route path="/internal-server-error" element={<ErrorPage />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
      {import.meta.env.VITE_DEBUG === "1" && <ReactQueryDevtools />}
    </>
  );
}

export default AppRoutes;
