import { Button, Typography } from "@mui/material";
import {
  ButtonRow,
  ConfirmationModal,
  DetailsList,
  DetailsListItem,
  formatDollarAmount,
  FormButton,
  getColDefCell,
  IContractCredit,
  Modal,
  useAccount,
  useConfirmationModal,
  useContractsSummary,
  useCredits,
  useCreditsMutations,
  useModal,
  UseModalProps,
  useToast,
} from "@synota-io/synota-shared-ui";
import { CreditFormModal } from "./CreditFormModal";
import { DeleteForever, Edit } from "@mui/icons-material";
import { creditsColDef } from "../pages/CreditsPage";

type Props = UseModalProps & {
  credit: IContractCredit | null;
};

export const CreditDetailModal = ({ credit, open, onClose }: Props) => {
  const { isAdmin, isSupplier, jwt } = useAccount();
  const { deleteCredit, isDeleteCreditPending } = useCreditsMutations();

  const { refetch: refetchContracts } = useContractsSummary();
  const { refetch: refetchCredits } = useCredits();

  const confirmationModal = useConfirmationModal();
  const editCreditModal = useModal();
  const toast = useToast();

  if (!credit) {
    return null;
  }

  return (
    <Modal
      fullWidth
      title="Credit"
      open={open}
      onClose={onClose}
      actions={
        <ButtonRow width="100%">
          <Button color="error" fullWidth onClick={onClose}>
            Close
          </Button>
          {isAdmin && isSupplier ? (
            <Button
              onClick={() => {
                editCreditModal.onOpen();
              }}
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<Edit />}
            >
              Edit
            </Button>
          ) : null}
          {isAdmin && isSupplier && credit.amount === credit.amount_remaining ? (
            <FormButton
              color="error"
              variant="contained"
              fullWidth
              isLoading={isDeleteCreditPending}
              onClick={() =>
                confirmationModal.onOpen({
                  acceptLabel: "Delete",
                  title: `You are about to delete a credit for ${formatDollarAmount(Number(credit.amount))}`,
                  onConfirm: () => {
                    confirmationModal.onClose();
                    deleteCredit(
                      {
                        jwt,
                        credit_shared_uuid: credit.shared_uuid,
                      },
                      {
                        onSuccess: () => {
                          onClose();
                          refetchCredits();
                          refetchContracts();
                          toast.success("Credit deleted successfully.");
                        },
                      },
                    );
                  },
                })
              }
            >
              <DeleteForever />
              &nbsp;Delete
            </FormButton>
          ) : null}
        </ButtonRow>
      }
    >
      <DetailsList disablePadding dense>
        {creditsColDef.map((column) => {
          const value = getColDefCell(column, credit, { current: {} } as any);
          if (!value) {
            return null;
          }
          return (
            <DetailsListItem xs key={column.field} title={column.headerName || column.field}>
              <span>{value}</span>
              {column.description ? (
                <Typography color="text.disabled" variant="inherit">
                  {column.description}
                </Typography>
              ) : null}
            </DetailsListItem>
          );
        })}
      </DetailsList>
      {editCreditModal.open && (
        <CreditFormModal
          {...editCreditModal}
          credit={credit}
          onSuccess={() => {
            refetchContracts();
            refetchCredits();
          }}
        />
      )}
      <ConfirmationModal {...confirmationModal} />
    </Modal>
  );
};
