import { Tooltip } from "@mui/material";
import { SwitchField } from "@synota-io/synota-shared-ui";
import { Control } from "react-hook-form";

export const CreateAnotherField = ({ control }: { control: Control<any, any> }) => {
  return (
    <Tooltip
      placement="left"
      arrow
      title="Enable to add another entry right after submitting this one"
    >
      <span>
        <SwitchField label="Create Another" name="createAnother" control={control} />
      </span>
    </Tooltip>
  );
};
