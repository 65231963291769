import { Typography, Box, Divider, ListItem, ListItemText, MenuList, Stack } from "@mui/material";
import { LOCATION_NAME } from "../../constants";
import {
  DASHBOARD_PATH,
  EXPLORER_SETTLEMENTS_PATH,
  EXPLORER_REPORTS_PATH,
  EXPLORER_DEPOSIT_HISTORY_PATH,
  ADHOC_INVOICES_PATH,
  CONTRACTS_PATH,
  CREDITS_PATH,
} from "../../paths";
import {
  BitcoinPriceTicker,
  Link,
  MenuItem,
  useAccount,
  useLayout,
} from "@synota-io/synota-shared-ui";
import {
  AccountBalanceOutlined,
  ArticleOutlined,
  DashboardOutlined,
  ElectricBoltOutlined,
  FindInPageOutlined,
  PaymentsOutlined,
  RequestQuoteOutlined,
} from "@mui/icons-material";
import { CONSUMER_TOS_URL, DEBUG_ENABLED } from "../../utils/environment";

export const AppMenu = () => {
  const { isMobile } = useLayout();
  const { hasLightningNode, isConsumer, unapprovedAdhocInvoices, supportsAdhocInvoices } =
    useAccount();

  return (
    <Stack height="100%">
      <MenuList>
        <MenuItem exact Icon={DashboardOutlined} title="Dashboard" path={DASHBOARD_PATH} />

        <MenuItem Icon={ArticleOutlined} title={LOCATION_NAME} path={CONTRACTS_PATH} />

        <MenuItem
          Icon={ElectricBoltOutlined}
          title="Settlements"
          path={EXPLORER_SETTLEMENTS_PATH}
        />

        {isConsumer && hasLightningNode && (
          <MenuItem
            Icon={AccountBalanceOutlined}
            title="Deposits"
            path={EXPLORER_DEPOSIT_HISTORY_PATH}
          />
        )}

        <MenuItem Icon={FindInPageOutlined} title="Reports" path={EXPLORER_REPORTS_PATH} />

        {supportsAdhocInvoices && (
          <MenuItem
            Icon={RequestQuoteOutlined}
            title="Ad Hoc Invoices"
            path={ADHOC_INVOICES_PATH}
            badge={unapprovedAdhocInvoices}
            badgeColor="warning"
          />
        )}

        <MenuItem Icon={PaymentsOutlined} title="Credits" path={CREDITS_PATH} />

        {isMobile && isConsumer && hasLightningNode ? (
          <Box pt={8}>
            <Divider />
            <ListItem>
              <ListItemText sx={{ py: 3 }}>
                <BitcoinPriceTicker />
              </ListItemText>
            </ListItem>
          </Box>
        ) : null}
      </MenuList>
      <Box flexGrow={1} />

      <Stack alignItems="center">
        {isConsumer && (
          <Link
            variant="body1"
            color="text.primary"
            href={CONSUMER_TOS_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </Link>
        )}
        {DEBUG_ENABLED ? <Typography>Frontend v{APP_VERSION}</Typography> : null}
      </Stack>
    </Stack>
  );
};
