import {
  ButtonLink,
  ButtonLinkProps,
  ButtonRow,
  IContractSummary,
  useAccount,
} from "@synota-io/synota-shared-ui";
import { generatePath, useMatch } from "react-router-dom";
import {
  ADHOC_INVOICES_PATH,
  CONTRACT_EXPOSURE_PATH,
  CONTRACT_PATH,
  CREDITS_PATH,
} from "../../../paths";
import { ContractMoreMenu } from "./ContractMoreMenu";
import { useContractActions } from "../hooks/useContractActions";
import { FEATURE_ENABLE_AR_EXPOSURE } from "../../../utils/environment";

const contractSearch = (contract: IContractSummary) => "?contract=" + contract.uuid;

interface Props {
  contract: IContractSummary | null;
}

export const ContractMenu = ({ contract }: Props) => {
  const { supportsAdhocInvoices } = useAccount();
  const contractActions = useContractActions({ contract });

  if (!contract) {
    return null;
  }

  const buttons = [
    {
      label: "Exposure",
      title: "A/R Exposure Calculator",
      path: CONTRACT_EXPOSURE_PATH,
      enabled: FEATURE_ENABLE_AR_EXPOSURE,
    },
    {
      label: "Ad Hoc",
      title: "Ad Hoc Invoices",
      path: ADHOC_INVOICES_PATH,
      search: contractSearch,
      enabled: supportsAdhocInvoices,
    },
    {
      label: "Credits",
      path: CREDITS_PATH,
      search: contractSearch,
      enabled: true,
    },
    {
      label: "Dashboard",
      path: CONTRACT_PATH,
      enabled: true,
    },
  ];

  return (
    <ButtonRow role="menu" gap={1}>
      {buttons
        .filter((b) => b.enabled)
        .map((button) => (
          <ContractMenuButton
            title={button.title || button.label}
            key={button.label}
            path={button.path}
            to={
              generatePath(button.path, { contractId: contract?.uuid || null }) +
              (button.search ? button.search(contract) : "")
            }
          >
            {button.label}
          </ContractMenuButton>
        ))}
      <ContractMoreMenu actions={contractActions} size="small" contract={contract} />
    </ButtonRow>
  );
};

const ContractMenuButton = ({ children, path, ...props }: ButtonLinkProps & { path: string }) => {
  const isActive = Boolean(useMatch({ path, end: true }));
  return (
    <ButtonLink
      role="menuitem"
      size="small"
      sx={{ whiteSpace: "nowrap" }}
      color={isActive ? "primary" : "neutral"}
      aria-selected={isActive}
      {...props}
    >
      {children}
    </ButtonLink>
  );
};
