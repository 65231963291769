import { CardContent, Stack, Typography } from "@mui/material";
import {
  EStrikeOAuthFeature,
  IBalanceCurrency,
  StatsBox,
  StatsTitle,
  formatAmount,
  formatBitcoinAmount,
  useCurrentUsdBalance,
  useStrikeConfiguration,
} from "@synota-io/synota-shared-ui";
import { CurrentContractsSummary } from "./CurrentContractsSummary";
import { StatCard } from "./StatCard";
import { Link } from "react-router-dom";
import { SETTINGS_PATH } from "../../../paths";
import { ReactElement } from "react";
import { StrikeLogo } from "../../../shared/media/StrikeLogo";

const BalanceTypography = ({ currency, value }: { currency: string; value: string }) => {
  return (
    <Stack direction="row" gap={1}>
      <Typography color="text.secondary" variant="subtitle2">
        {currency}
      </Typography>
      <Typography variant="subtitle2">{value}</Typography>
    </Stack>
  );
};

const DefaultBalance = ({ currency, value }: { currency: string; value: string }) => (
  <BalanceTypography currency={currency} value={value} />
);

const BALANCE_TO_FORMAT_FN_MAP: Record<string, (value: string) => string | ReactElement> = {
  BTC: (value) => <BalanceTypography currency="BTC" value={formatBitcoinAmount(value)} />,
  USD: (value) => <BalanceTypography currency="USD" value={formatAmount(Number(value), 2)} />,
  USDT: (value) => <BalanceTypography currency="USDT" value={formatAmount(Number(value), 2)} />,
};

const BALANCE_TO_FORMAT_FN = (currency: IBalanceCurrency, value: string) => {
  const formatter =
    BALANCE_TO_FORMAT_FN_MAP[currency] ||
    ((value: string) => <DefaultBalance currency={currency} value={value} />);
  return formatter(value);
};

export const CurrentUsdBalanceSummary = () => {
  const { balances, description } = useCurrentUsdBalance();

  const valuesArray = Object.entries(balances || {});

  const { hasAllFeatures: isStrike } = useStrikeConfiguration({
    features: [EStrikeOAuthFeature.GetBalance],
  });

  return (
    <Stack direction="row" gap={6} flexGrow={1} flexWrap="wrap">
      {valuesArray.length ? (
        <StatCard sx={{ height: "unset" }}>
          <CardContent>
            <StatsBox
              slotProps={{
                items: { flexDirection: "row", alignItems: "end", gap: 4 },
              }}
              component={Link}
              to={SETTINGS_PATH}
              flexGrow={1}
              variant="primary"
              disablePadding
              description={description}
              title={<StatsTitle title="Balance" action={isStrike ? <StrikeLogo /> : null} />}
              items={valuesArray
                .filter(([k, v]) => ["BTC", "USD"].includes(k) || Number(v) !== 0)
                .sort(([a], [b]) => a.localeCompare(b))
                .map(([key, value]) => BALANCE_TO_FORMAT_FN(key as IBalanceCurrency, value))}
            />
          </CardContent>
        </StatCard>
      ) : null}
      <Stack flexGrow={1} maxWidth="50%">
        <CurrentContractsSummary />
      </Stack>
    </Stack>
  );
};
