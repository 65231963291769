import { Box, Stack, Tooltip } from "@mui/material";
import {
  ButtonRow,
  ConfirmationModal,
  ContractSearchField,
  DateField,
  Dayjs,
  Form,
  FormButton,
  FormStep,
  FormSubmit,
  IContractCredit,
  IContractSummary,
  InputField,
  Modal,
  NumericField,
  UseModalProps,
  dayjs,
  formatDollarAmount,
  isDayjs,
  useAccount,
  useConfirmationModal,
  useCreditsMutations,
  useToast,
} from "@synota-io/synota-shared-ui";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { Help } from "@mui/icons-material";
import { CreateAnotherField } from "./CreateAnotherField";

export interface CreateCreditFormValues {
  createAnother?: boolean;
  contract: { uuid: string; name: string; label: string; value: string } | null;
  usdAmount: number;
  effective_date: Dayjs | null;
  credit_memo_number: string;
  service_start_time: Dayjs | null;
  service_end_time: Dayjs | null;
  title: string;
  description: string;
}

export const createInvoiceSchema = yup.object({
  createAnother: yup.bool(),
  contract: yup
    .object({
      uuid: yup.string().required("Invalid contract"),
      name: yup.string(),
    })
    .required("Contract is required"),
  usdAmount: yup
    .number()
    .typeError("USD Amount is required")
    .min(0, "USD Amount is required")
    .required("USD Amount is required"),
  credit_memo_number: yup.string(),
  effective_date: yup.object().required("Effective date is required"),
  service_start_time: yup.object().required("Period start is required"),
  service_end_time: yup.object().required("Period end is required"),
  description: yup.string(),
  title: yup.string().required("A title is required"),
});

const getFormValues = (contract?: IContractSummary, credit?: IContractCredit) => {
  if (credit) {
    return {
      contract: contract
        ? { label: contract.name, value: contract.uuid, ...contract }
        : {
            label: credit.contract_name,
            value: credit.contract_shared_uuid,
            uuid: credit.contract_shared_uuid,
            name: credit.contract_name,
          },
      usdAmount: Number(credit?.amount),
      credit_memo_number: credit?.credit_memo_number,
      effective_date: dayjs(credit?.effective_date),
      service_start_time: dayjs(credit?.service_start_time),
      service_end_time: dayjs(credit?.service_end_time),
      description: credit?.description,
      title: credit?.title,
    };
  }

  return {
    contract: contract ? { label: contract.name, value: contract.uuid, ...contract } : null,
    usdAmount: "" as unknown as number,
    credit_memo_number: "",
    effective_date: dayjs(),
    service_start_time: dayjs(),
    service_end_time: dayjs(),
    description: "",
    title: "",
  };
};

export const CreditFormModal = ({
  contract: initialContract,
  open,
  onClose,
  onSuccess,
  credit,
}: UseModalProps & {
  onSuccess: (values: CreateCreditFormValues) => void;
  contract?: IContractSummary;
  credit?: IContractCredit;
}) => {
  const toast = useToast();
  const { jwt } = useAccount();
  const { isPending, isPartiallyEditCreditPending, addCredit, partiallyEditCredit } =
    useCreditsMutations();

  const isEditMode = !!credit;

  const [hasSetEndDate, setHasSetEndDate] = useState(false);

  const { control, handleSubmit, watch, setValue, trigger, formState, reset } =
    useForm<CreateCreditFormValues>({
      resolver: yupResolver(createInvoiceSchema) as any,
      defaultValues: isEditMode
        ? getFormValues(initialContract, credit)
        : getFormValues(initialContract),
    });

  const values = watch();

  const confirmModal = useConfirmationModal();

  const onSubmit = handleSubmit((values) => {
    if (!values.contract) {
      return;
    }
    const {
      createAnother,
      usdAmount,
      effective_date,
      service_start_time,
      service_end_time,
      contract,
      ...rest
    } = values;
    confirmModal.onOpen({
      title: isEditMode
        ? `Update the credit of ${formatDollarAmount(usdAmount)} for contract "${contract.name}"?`
        : `Create a credit of ${formatDollarAmount(usdAmount)} for contract "${contract.name}"?`,
      onConfirm() {
        confirmModal.onClose();

        if (credit) {
          partiallyEditCredit(
            {
              jwt,
              contract_shared_uuid: contract.uuid,
              effective_date: (effective_date as Dayjs).startOf("day").toISOString(),
              service_start_time: (service_start_time as Dayjs).toISOString(),
              service_end_time: (service_end_time as Dayjs).toISOString(),
              credit_shared_uuid: credit.shared_uuid,
              ...rest,
            },
            {
              onSuccess: () => {
                toast.success("Credit updated successfully.");
                onSuccess(values);
                reset(values);
              },
            },
          );

          return;
        }

        addCredit(
          {
            jwt,
            amount: String(usdAmount),
            contract_shared_uuid: contract.uuid,
            effective_date: (effective_date as Dayjs).startOf("day").toISOString(),
            service_start_time: (service_start_time as Dayjs).toISOString(),
            service_end_time: (service_end_time as Dayjs).toISOString(),
            ...rest,
          },
          {
            onSuccess: () => {
              onSuccess(values);
              toast.success("Credit created successfully.");
              if (createAnother) {
                return reset({
                  contract: values.contract,
                  effective_date: values.effective_date,
                  service_start_time: values.service_start_time,
                  service_end_time: values.service_end_time,
                  createAnother: true,
                });
              }
              onClose();
            },
          },
        );
      },
    });
  });

  return (
    <>
      <Modal
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={onClose}
        title={`${isEditMode ? "Update" : "Add"} credit for contract`}
        actions={
          <ButtonRow width="100%">
            <FormButton
              variant="text"
              color="error"
              onClick={() => {
                onClose();
              }}
            >
              Close
            </FormButton>
            {!isEditMode && <CreateAnotherField control={control} />}
            <Box flexGrow={1} ml={3} display="flex">
              <FormSubmit
                disabled={!formState.isDirty}
                isLoading={isPending || isPartiallyEditCreditPending}
                form="create-credit-form"
                fullWidth
              />
            </Box>
          </ButtonRow>
        }
      >
        <Form id="create-credit-form" onSubmit={onSubmit}>
          <FormStep>
            <ContractSearchField
              readOnly={isEditMode}
              disabled={isEditMode}
              control={control}
              size="small"
              header="Choose a contract"
              placeholder="Select here"
              name="contract"
              fullWidth
            />

            <InputField
              size="small"
              header="Header/Title"
              autoFocus
              fullWidth
              control={control}
              name="title"
            />

            <ButtonRow>
              <DateField
                header="Service Date(s)"
                size="small"
                label="Start date"
                fullWidth
                maxDate={isDayjs(values.service_end_time) ? values.service_end_time : undefined}
                control={control}
                name="service_start_time"
                onChange={(value) => {
                  if (value && !hasSetEndDate) {
                    setValue("service_end_time", value);
                    trigger("service_end_time");
                  }
                }}
              />
              <DateField
                fullWidth
                header="&nbsp;"
                size="small"
                minDate={isDayjs(values.service_start_time) ? values.service_start_time : undefined}
                label="End date"
                onChange={() => {
                  setHasSetEndDate(true);
                }}
                control={control}
                name="service_end_time"
              />
            </ButtonRow>
            <ButtonRow>
              <NumericField
                size="small"
                header="Amount"
                readOnly={isEditMode}
                disabled={isEditMode}
                fullWidth
                autoComplete="off"
                slotProps={{ input: { endAdornment: "$" } }}
                control={control}
                name="usdAmount"
              />
              <DateField
                size="small"
                header={
                  <Stack lineHeight={1.3} component="span" direction="row" spacing={2}>
                    <span>Effective Date</span>
                    <Tooltip
                      arrow
                      title="Credit applies on or after energy day for the selected date"
                    >
                      <span>
                        <Help fontSize="small" />
                      </span>
                    </Tooltip>
                  </Stack>
                }
                fullWidth
                control={control}
                name="effective_date"
              />
            </ButtonRow>
            <InputField
              size="small"
              header="Credit Memo #"
              fullWidth
              autoComplete="off"
              control={control}
              name="credit_memo_number"
            />
            <InputField
              size="small"
              header="Description"
              fullWidth
              control={control}
              name="description"
            />
          </FormStep>
        </Form>
      </Modal>
      {confirmModal.open && <ConfirmationModal {...confirmModal} />}
    </>
  );
};
