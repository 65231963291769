import {
  ActionButton,
  REPORT_ENDPOINTS,
  formatDate,
  formatDollarAmount,
  useAccount,
  useBreakpoint,
  useCurrencyConverter,
  useModal,
  useReportFiltersForm,
  DataGridPage,
  useGridServerPagination,
  IContractCredit,
  useCredits,
  useContractsSummary,
  BaseDataGrid,
  useDefaultError,
} from "@synota-io/synota-shared-ui";
import { useEffect, useState } from "react";
import { Add } from "@mui/icons-material";
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridPaginationModel,
  useGridApiRef,
} from "@mui/x-data-grid";
import { CreditDetailModal } from "../components/CreditDetailsModal";
import { CreateCreditFormValues, CreditFormModal } from "../components/CreditFormModal";
import { Button } from "@mui/material";
import { ContractCell } from "../cells/ContractCell";
import { ReportFiltersToolbarLinked } from "../../../shared/reports/ReportFiltersToolbarLinked";
import { generatePath, useLocation, useNavigate, useParams } from "react-router-dom";
import { CREDIT_PATH } from "../../../paths";

const defaultDates = {
  startDate: null,
  endDate: null,
};

export const creditsColDef: GridColDef<IContractCredit>[] = [
  {
    field: "contract_name",
    headerName: "Contract",
    renderCell: ({ row }) => <ContractCell row={row} />,
    flex: 1,
    width: 220,
    minWidth: 120,
  },
  {
    field: "shared_numeric_id",
    type: "number",
    headerName: "Credit ID",
    width: 125,
  },
  {
    field: "title",
    headerName: "Title",
    flex: 1,
    width: 220,
    minWidth: 120,
  },
  {
    field: "effective_date",
    headerName: "Effective Date",
    valueGetter: (value) => formatDate(value),
    width: 140,
    minWidth: 90,
  },
  {
    field: "amount",
    type: "number",
    headerName: "Amount",
    valueFormatter: (value) => formatDollarAmount(Number(value || 0)),
    width: 140,
    minWidth: 90,
  },
  {
    field: "amount_remaining",
    type: "number",
    valueFormatter: (value) => formatDollarAmount(Number(value || 0)),
    headerName: "Amount Remaining",
    width: 140,
    minWidth: 90,
  },
  {
    field: "service_start_time",
    type: "number",
    headerName: "Start Date",
    description: "Service start date",
    valueFormatter: (value) => formatDate(value),
    width: 140,
    minWidth: 90,
  },
  {
    field: "service_end_time",
    headerName: "End Date",
    description: "Service end date",
    valueFormatter: (value) => formatDate(value),
    width: 140,
    minWidth: 90,
  },
  {
    field: "created_at",
    type: "number",
    headerName: "Created At",
    valueFormatter: (value) => formatDate(value),
    width: 140,
    minWidth: 90,
  },
  {
    field: "credit_memo_number",
    type: "number",
    headerName: "Credit Memo #",
    width: 110,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    width: 220,
    minWidth: 120,
  },
];

export const CreditsPage = () => {
  const apiRef = useGridApiRef();
  const isTablet = useBreakpoint("sm");
  const params = useParams();
  const navigate = useNavigate();

  const { search } = useLocation();
  const { isAdmin, isSupplier, paymentMethod } = useAccount();

  const { isBluePenguin: isCurrencyConverterBluePenguin } = useCurrencyConverter();
  const isBluePenguin = Boolean(isCurrencyConverterBluePenguin || paymentMethod?.isBluePenguin);

  const addModal = useModal();
  const detailModal = useModal();

  const [paginationModel, onPaginationModelChange] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 20,
  });

  const {
    form: { control },
    input,
    values,
  } = useReportFiltersForm({
    report: REPORT_ENDPOINTS.CREDITS,
    defaultDates,
  });

  const { refetch: refetchContracts } = useContractsSummary();

  const {
    credits,
    hasNextPage,
    isFetching,
    refetch: refetchCredits,
    error,
  } = useCredits({
    ...input,
    pagination_i: paginationModel.page,
  });

  useDefaultError(error, "There was an error while loading credits, please try again.");

  useEffect(() => {
    if (!detailModal.open && !isFetching && params.creditId) {
      detailModal.onOpen({ item: credits.find((c) => c.shared_uuid === params.creditId) });
    }
  }, [credits, detailModal, isFetching, params.creditId]);

  const [columnVisibility, setColumnVisibility] = useState<GridColumnVisibilityModel>({});
  useEffect(() => {
    setColumnVisibility({
      contract_name: isTablet,
      title: isTablet,
      service_start_time: isTablet,
      service_end_time: isTablet,
      created_at: isTablet,
      credit_memo_number: isTablet,
      description: isTablet,
    });
  }, [isTablet, credits, isBluePenguin]);

  const [lastCreation, setLastCreation] = useState<null | CreateCreditFormValues>(null);
  useEffect(() => {
    if (
      credits[0] &&
      lastCreation?.service_start_time?.toISOString() === credits[0].service_start_time
    ) {
      apiRef.current?.setRowSelectionModel([credits[0].shared_uuid]);
    }
  }, [apiRef, credits, lastCreation]);
  const onCreditCreated = (values: CreateCreditFormValues) => {
    setLastCreation(values);
    refetchContracts();
    refetchCredits();
  };

  const paginationProps = useGridServerPagination({
    hasNextPage,
    currentPageRowCount: credits.length,
    paginationModel,
    onPaginationModelChange,
  });

  const onDetailsOpen = (row: IContractCredit) => {
    navigate(
      {
        pathname: generatePath(CREDIT_PATH, { creditId: row.shared_uuid }),
        search,
      },
      { replace: true },
    );
  };

  const columns: GridColDef<IContractCredit>[] = [
    ...creditsColDef,
    {
      field: "more",
      headerName: "More",
      type: "actions",
      disableExport: true,
      disableReorder: true,
      renderCell: ({ row }) => {
        return (
          <Button
            size="small"
            sx={{ padding: 0 }}
            color="primary"
            variant="contained"
            onClick={() => onDetailsOpen(row)}
          >
            Details
          </Button>
        );
      },
    },
  ];

  return (
    <DataGridPage
      title="Credits"
      recalculateHeightDeps={values}
      toolbar={<ReportFiltersToolbarLinked control={control} />}
      actions={
        isSupplier && isAdmin ? (
          <ActionButton
            onClick={() => addModal.onOpen()}
            isLoading={isFetching}
            title="Add credit"
            color="primary"
            size="small"
          >
            <Add />
          </ActionButton>
        ) : null
      }
    >
      <>
        <BaseDataGrid
          apiRef={apiRef}
          loading={isFetching}
          columns={columns}
          rows={credits}
          onRowDoubleClick={({ row }) => onDetailsOpen(row)}
          getRowId={(row) => row.shared_uuid}
          columnVisibilityModel={columnVisibility}
          onColumnVisibilityModelChange={setColumnVisibility}
          {...paginationProps}
        />
        {addModal.open && <CreditFormModal {...addModal} onSuccess={onCreditCreated} />}
        <CreditDetailModal
          key={params.creditId}
          credit={credits?.find((c) => c.shared_uuid === params.creditId) || null}
          {...detailModal}
          onClose={() =>
            navigate(
              { pathname: "..", search },
              { preventScrollReset: true, relative: "path", replace: true },
            )
          }
        />
      </>
    </DataGridPage>
  );
};
