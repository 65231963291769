import { GridRenderCellParams } from "@mui/x-data-grid";
import { IAdhocInvoice, IContractCredit, Link } from "@synota-io/synota-shared-ui";
import { generatePath } from "react-router-dom";
import { CONTRACT_PATH } from "../../../paths";

export const ContractCell = ({
  row,
}: Pick<GridRenderCellParams<IAdhocInvoice | IContractCredit>, "row">) => {
  return row.contract_shared_uuid ? (
    <Link
      title={row.contract_name || row.contract_shared_uuid}
      sx={{ textDecoration: "none" }}
      color="text.primary"
      to={generatePath(CONTRACT_PATH, { contractId: row.contract_shared_uuid })}
    >
      {row.contract_name || row.contract_shared_uuid}
    </Link>
  ) : (
    row.contract_name || "N/A"
  );
};
