// Root
const DASHBOARD_PATH = "/";
const SETTINGS_PATH = "/settings";
const SETTINGS_ALERTS_PATH = "/settings/alerts";

// Contracts
const CONTRACTS_PATH = "/contracts";
const CONTRACT_PATH = "/contracts/:contractId";
const CONTRACT_EXPOSURE_PATH = "/contracts/:contractId/exposure";
const CONTRACT_CREDITS_PATH = "/contracts/:contractId/credits";

// Explorer
const EXPLORER_PATH = "/data";
const EXPLORER_DEPOSIT_HISTORY_PATH = "/data/deposit-history";
const EXPLORER_SETTLEMENTS_PATH = "/data/settlements";
const EXPLORER_REPORTS_PATH = "/data/reports";
const EXPLORER_CHANGELOG_PATH = "/data/changelog";
const EXPLORER_USERS_PATH = "/data/users";

// Account
const ACCOUNT_PATH = "/account";
const SIGNUP_PATH = "/account/signup";
const LOGIN_PATH = "/account/login";
const ACCOUNT_TOS_PATH = "/account/terms-of-service";
const RESET_PASSWORD_PATH = "/account/reset-password";
const ACCOUNT_SET_UP_PATH = "/account/set-up";

// Charges
const CHARGES_PATH = "/charges";
const ADHOC_INVOICES_PATH = "/charges/adhoc";
const ADHOC_INVOICE_PATH = "/charges/adhoc/:chargeId";
const CREDITS_PATH = "/charges/credits";
const CREDIT_PATH = "/charges/credits/:creditId";

export {
  DASHBOARD_PATH,
  EXPLORER_PATH,
  CONTRACTS_PATH,
  CONTRACT_PATH,
  CONTRACT_EXPOSURE_PATH,
  ACCOUNT_PATH,
  LOGIN_PATH,
  SIGNUP_PATH,
  RESET_PASSWORD_PATH,
  ACCOUNT_TOS_PATH,
  ACCOUNT_SET_UP_PATH,
  EXPLORER_SETTLEMENTS_PATH,
  EXPLORER_REPORTS_PATH,
  EXPLORER_DEPOSIT_HISTORY_PATH,
  EXPLORER_CHANGELOG_PATH,
  CONTRACT_CREDITS_PATH,
  SETTINGS_PATH,
  EXPLORER_USERS_PATH,
  SETTINGS_ALERTS_PATH,
  CHARGES_PATH,
  ADHOC_INVOICES_PATH,
  ADHOC_INVOICE_PATH,
  CREDITS_PATH,
  CREDIT_PATH,
};
