import { useEffect, useMemo } from "react";
import { Download } from "@mui/icons-material";
import {
  EStrikeOAuthFeature,
  ReportItem,
  REPORT_ENDPOINTS,
  useAccount,
  useReportCsv,
  useReportFiltersForm,
  useStrikeConfiguration,
  useDefaultError,
  useCurrencyConverter,
  ActionButton,
  ReportDataGrid,
  DataGridPage,
} from "@synota-io/synota-shared-ui";
import { ReportFiltersToolbarLinked } from "../../../shared/reports/ReportFiltersToolbarLinked";

const ReportsPage = () => {
  const account = useAccount();

  const {
    hasLightningNode,
    isConsumer,
    isSupplier,
    userGroupId,
    self,
    paymentMethod,
    isHost,
    isReady,
  } = account;

  const { isBluePenguin: isCurrencyConverterBluePenguin, isStrike: isCurrencyConverterStrike } =
    useCurrencyConverter();

  const isBluePenguin = isCurrencyConverterBluePenguin || paymentMethod?.isBluePenguin;

  const isStrike = isCurrencyConverterStrike || paymentMethod?.isStrike;

  const { hasAllFeatures: isStrikeConnected } = useStrikeConfiguration({
    features: [EStrikeOAuthFeature.AutomaticPayout],
  });

  const reports: (ReportItem & { display: boolean })[] = useMemo(
    () =>
      [
        {
          ...REPORT_ENDPOINTS.CONTRACT_SUMMARY,
          display: true,
        },
        {
          ...REPORT_ENDPOINTS.DEPOSIT_DETAILS,
          display: Boolean(isConsumer && hasLightningNode && !isBluePenguin),
        },
        {
          ...REPORT_ENDPOINTS.INVOICE_DETAILS,
          display: true,
        },
        {
          ...REPORT_ENDPOINTS.PAYMENT_DETAILS,
          display: true,
        },
        {
          ...REPORT_ENDPOINTS.PAYMENT_FAILURE_DETAILS,
          display: Boolean(isBluePenguin),
        },
        {
          ...REPORT_ENDPOINTS.WITHDRAWAL_DETAILS,
          display: Boolean(isConsumer && !paymentMethod?.isNone && !isBluePenguin),
        },
        {
          ...REPORT_ENDPOINTS.REVENUE_AND_EXPENSES,
          display: Boolean(userGroupId && isSupplier),
        },
        {
          ...REPORT_ENDPOINTS.DISBURSEMENT_DETAILS,
          display: Boolean(isSupplier && isStrikeConnected && !isBluePenguin),
        },
        {
          ...REPORT_ENDPOINTS.STRIKE_RECONCILIATION,
          display: Boolean(isSupplier && isStrikeConnected && !isBluePenguin),
        },
        {
          ...REPORT_ENDPOINTS.STRIKE_LIQUIDATION,
          display: Boolean(isStrike),
        },
        {
          ...REPORT_ENDPOINTS.FEE_DETAILS,
          display: Boolean(
            (isConsumer || isStrikeConnected) && !paymentMethod?.isNone && !isBluePenguin,
          ),
        },
        {
          ...REPORT_ENDPOINTS.FEE_SUMMARY,
          display: Boolean(
            (isConsumer || isStrikeConnected) && !paymentMethod?.isNone && !isBluePenguin,
          ),
        },
        {
          ...REPORT_ENDPOINTS.NODE_RECONCILIATION,
          display: Boolean(isConsumer && hasLightningNode),
          hideHeader: true,
        },
        {
          ...REPORT_ENDPOINTS.REVENUE_AND_EXPENSES,
          display: Boolean(isHost && isConsumer),
        },
        {
          ...REPORT_ENDPOINTS.BROKER_EXPENSES,
          display: Boolean(isSupplier),
        },
      ].filter((dataTable) => dataTable.display !== false),
    [
      isConsumer,
      hasLightningNode,
      isBluePenguin,
      paymentMethod?.isNone,
      userGroupId,
      isSupplier,
      isStrikeConnected,
      isStrike,
      isHost,
    ],
  );

  const {
    form: { control, reset, watch },
    input,
    getFilename,
  } = useReportFiltersForm({
    report: reports[0],
    reports,
  });

  const filters = watch();

  useEffect(() => {
    if (!reports.find((r) => r.path === filters.report?.path) && filters.report.path !== "") {
      reset();
    }
  }, [reports, reset, self, filters]);

  const { error, isFetching, download } = useReportCsv({
    input,
    filename: getFilename(),
    options: {
      enabled: isReady,
    },
  });

  useDefaultError(error);

  return (
    <DataGridPage
      title="Reports"
      actions={
        <ActionButton
          color="primary"
          title="Download Report"
          size="small"
          onClick={download}
          isLoading={isFetching}
          disabled={!!error}
        >
          <Download fontSize="small" />
        </ActionButton>
      }
      toolbar={<ReportFiltersToolbarLinked items={reports} control={control} />}
    >
      <ReportDataGrid input={input} filters={filters} />
    </DataGridPage>
  );
};

export { ReportsPage };
